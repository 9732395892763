import React, { useEffect, useState } from "react"
import { Bar, Line } from "react-chartjs-2"
import { graphql, Link } from "gatsby"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Progress = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  const targetGraphOptions = {
    elements: { point: { radius: 0 } },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  }
  const options = {
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  }
  const [dateLabels, setDateLabels] = useState([])
  const [charactersLearnedData, setCharactersLearnedData] = useState({})
  const [timeSpentLearning, setTimeSpentLearning] = useState({})

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(
        "https://spreadsheets.google.com/feeds/cells/1i8UOcKY1Eqzs20rHGtmP9eNiwbmJgmyzjvchDMnk6fc/1/public/basic?alt=json"
      )

      let labelsArray = []
      let charactersLearned = {
        targetCharactersLearned: [],
        actualCharactersLearned: [],
      }
      let timeSpentLearning = {
        totalTime: [],
        timeOnCharacters: [],
        timeOnOther: [],
      }
      res.data.feed.entry.forEach(array => {
        let cellLocation = array.title.$t
        let cellValue = array.content.$t
        if (cellLocation[0] === "A" && cellLocation !== "A1") {
          labelsArray.push(cellValue)
        }
        if (cellLocation[0] === "B" && cellLocation !== "B1") {
          charactersLearned.targetCharactersLearned.push(cellValue)
        }
        if (cellLocation[0] === "C" && cellLocation !== "C1") {
          charactersLearned.actualCharactersLearned.push(cellValue)
        }
        if (cellLocation[0] === "I" && cellLocation !== "I1") {
          timeSpentLearning.totalTime.push(cellValue)
        }
        if (cellLocation[0] === "J" && cellLocation !== "J1") {
          timeSpentLearning.timeOnOther.push(cellValue)
        }
        if (cellLocation[0] === "K" && cellLocation !== "K1") {
          timeSpentLearning.timeOnCharacters.push(cellValue)
        }
      })
      setDateLabels(labelsArray)
      setCharactersLearnedData(charactersLearned)
      setTimeSpentLearning(timeSpentLearning)
    }
    fetch()
  }, [])

  const graphData = {
    labels: dateLabels,
    datasets: [
      {
        label: "Hours Spent Learning Chinese (Non-characters)",
        backgroundColor: "#ecc94b",
        borderColor: "#ecc94b",
        pointBackgroundColor: "#ecc94b",
        data: timeSpentLearning.timeOnOther,
      },
      {
        label: "Hours Spent Learning Characters",
        backgroundColor: "#667eea",
        borderColor: "#667eea",
        pointBackgroundColor: "#667eea",
        data: timeSpentLearning.timeOnCharacters,
      },

      {
        label: "Total Hours Spent Learning Chinese",
        backgroundColor: "#4c51bf",
        borderColor: "#4c51bf",
        pointBackgroundColor: "#4c51bf",
        data: timeSpentLearning.totalTime,
      },
    ],
  }

  const learningProgress = {
    labels: dateLabels,
    datasets: [
      {
        label: "Chinese Characters Learned (Target)",
        backgroundColor: "rgb(0,0,0,0)",
        borderColor: "#ecc94b",
        pointBackgroundColor: "#ecc94b",
        type: "line",
        data: charactersLearnedData.targetCharactersLearned,
      },
      {
        label: "Chinese Characters Learned",
        backgroundColor: "#4c51bf",
        data: charactersLearnedData.actualCharactersLearned,
      },
    ],
  }

  console.log(learningProgress.labels.length)

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Progress" />

      <section className="my-4 mb-8">
        <h1 className="text-3xl font-semibold text-indigo-700 text-center">
          Chinese Learning Progress
        </h1>
        <p className="text-center">
          If you're curious how I'm studying,{" "}
          <Link
            className="text-indigo-700 underline hover:text-indigo-800"
            to="/blog/im-going-to-learn-3000-chinese-characters-in-120-days/"
          >
            check out this blog post
          </Link>
          .
        </p>

        <h2 className="mt-5 text-center font-semibold text-indigo-700 text-xl">
          Total Days Learning Chinese
        </h2>
        <p className="text-center text-2xl font-bold text-yellow-600">
          {learningProgress.labels.length}{" "}
          <span className="text-sm font-medium text-gray-600 uppercase tracking-wide">
            days
          </span>
        </p>
        <div className="my-4">
          <h2 className="text-center font-semibold text-indigo-700 text-xl">
            Total Characters Learned
          </h2>
          <Bar options={targetGraphOptions} data={learningProgress} />
        </div>
      </section>
      <div className="my-4">
        <h2 className="text-center font-semibold text-indigo-700 text-xl">
          Total Hours Spent Learning
        </h2>
        <Line options={options} data={graphData} />
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
export default Progress
